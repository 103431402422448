import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";

import { Captions, Zoom } from "yet-another-react-lightbox/plugins";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as ChevronDownIcon } from "../assets/icons/chevron-down.svg";
import Lightbox from "yet-another-react-lightbox";
import products from "../assets/products";

const Gallery = () => {
  const location = useLocation();
  const listRef = useRef(null);
  const selectedProduct =
    decodeURIComponent(location?.hash).replace("#", "") || "";
  const [activeTab, setActiveTab] = useState(
    selectedProduct || "kostka_brukowa",
  );
  const [showList, setShowList] = useState(false);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  const ProductIcon = ({ name, title, image }) => {
    return (
      <div
        role="button"
        tabIndex={0}
        onClick={() => setActiveTab(name)}
        onKeyDown={() => setActiveTab(name)}
      >
        <div className="group flex items-center gap-[0.5em] py-[0.5em]">
          <div className="grid max-w-[1em] place-items-center">
            <img src={require(`../assets/images/${image}`)} alt={title} />
          </div>
          <button
            className={`font-bold text-black transition ease-linear group-hover:text-red ${activeTab == name && "text-red"}`}
          >
            {title}
          </button>
        </div>
      </div>
    );
  };

  const GalleryImage = ({ index, name, manufacturer, imagesFolder, image }) => {
    return (
      <div className="flex flex-col shadow-md">
        <div
          className="h-[10rem] cursor-pointer md:h-[15rem]"
          role="button"
          tabIndex="0"
          onClick={() => {
            setOpenLightbox(true);
            setSlideIndex(index);
          }}
          onKeyDown={() => {
            setOpenLightbox(true);
            setSlideIndex(index);
          }}
        >
          <img
            src={require(`../assets/images/gallery/${imagesFolder}/${image}`)}
            alt={name}
            className="h-full w-full object-cover object-center"
          />
        </div>
        {name && (
          <div className="hidden justify-between p-[1em] md:flex">
            <h4 className="text-black">{name}</h4>
            {manufacturer && (
              <img
                src={require(`../assets/images/${manufacturer}-logo.webp`)}
                alt={`${manufacturer} logo`}
                className="max-h-[2em] object-cover"
              />
            )}
          </div>
        )}
      </div>
    );
  };

  const GalleryElement = () => {
    const element = products.find((obj) => obj?.name === activeTab) || {};

    return (
      <div
        id={element?.name}
        className="flex flex-col gap-[2em] bg-white p-[1em] transition-[height] ease-linear md:p-[5em]"
      >
        <div>
          <div
            ref={listRef}
            role="button"
            tabIndex="0"
            onClick={() => setShowList(!showList)}
            onKeyDown={() => setShowList(!showList)}
            className="relative flex w-fit cursor-pointer items-center gap-[1em] md:cursor-default"
          >
            <h3 className="mb-[0.5em]">{element?.title}</h3>
            <ChevronDownIcon
              width="1em"
              className={`mb-[1em] md:hidden ${showList && "rotate-180"}`}
            />
            <div
              className={`${showList ? "flex md:hidden" : "hidden"} absolute left-0 top-[2em] w-[13rem] flex-col bg-gray px-[1em] shadow-md `}
            >
              {products
                .filter(({ name }) => name !== activeTab)
                .map(({ name, title }) => (
                  <Link
                    to={`/galeria#${name}`}
                    key={name}
                    onClick={() => {
                      setActiveTab(name);
                      setShowList(false);
                    }}
                    onKeyDown={() => {
                      setActiveTab(name);
                      setShowList(false);
                    }}
                    className="py-[0.5em] font-bold transition ease-linear hover:text-red"
                  >
                    {title}
                  </Link>
                ))}
            </div>
          </div>
          <p>{element?.description}</p>
        </div>

        {element?.images?.length > 0 ? (
          <div className="grid grid-cols-2 gap-[1em] sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3">
            {element?.images.map((image, index) => (
              <GalleryImage
                key={index}
                index={index}
                name={image?.name}
                manufacturer={image?.manufacturer}
                imagesFolder={element?.name}
                image={image?.src}
              />
            ))}
            <Lightbox
              open={openLightbox}
              plugins={[Captions, Zoom]}
              captions={{ descriptionTextAlign: "center" }}
              index={slideIndex}
              close={() => setOpenLightbox(false)}
              slides={element?.images.map((image) => ({
                src: require(
                  `../assets/images/gallery/${element?.name}/${image?.src}`,
                ),
                description: image?.name,
              }))}
            />
          </div>
        ) : (
          <div className="text-center text-slate-500">
            Brak obrazów w galerii
          </div>
        )}
      </div>
    );
  };

  const handleClickOutside = (event) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      setShowList(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section
      id="gallery"
      className="min-h-screen md:grid md:grid-cols-[20%_auto]"
    >
      <div className="hidden h-full min-w-[12rem] flex-col bg-gray px-[3em] py-[5em] md:flex">
        <h3 className="mb-[0.5em]">Galeria</h3>
        <div className="flex w-fit flex-col">
          <ProductIcon
            name="kostka_brukowa"
            title="Kostka brukowa"
            image="paving-stones.webp"
          />
          <ProductIcon
            name="kruszywa"
            title="Kruszywa"
            image="aggregate.webp"
          />
          <ProductIcon
            name="piasek_dekoracyjny"
            title="Piasek dekoracyjny"
            image="decorative-sand.webp"
          />
          <ProductIcon
            name="kamienie_dekoracyjne"
            title="Kamienie dekoracyjne"
            image="decorative-stones.webp"
          />
          <ProductIcon
            name="ogrodzenia_panelowe"
            title="Ogrodzenia panelowe"
            image="fencing.webp"
          />
          <ProductIcon
            name="ogrodzenia_modułowe"
            title="Ogrodzenia modułowe"
            image="fencing.webp"
          />
          <ProductIcon name="beton" title="Beton" image="concrete.webp" />
          <ProductIcon name="chemia" title="Chemia" image="chemicals.webp" />
        </div>
      </div>

      <div
        className="flex h-[5.5em] flex-col items-center justify-center p-[1em] md:hidden"
        style={{
          backgroundImage: `linear-gradient(rgba(238, 238, 238, 0.95), rgba(238, 238, 238, 0.95)), url(${require("../assets/backgrounds/bg-img-1.webp")}`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h2>Galeria</h2>
        <hr className="h-[0.25em] w-[40%] bg-red" />
      </div>
      <GalleryElement />
    </section>
  );
};

export default Gallery;
