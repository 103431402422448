const products = [
  {
    id: 1,
    name: "kostka_brukowa",
    title: "Kostka brukowa",
    description:
      "Oferujemy kostkę brukową w różnych kolorach i wzorach, jest odporna na warunki atmosferyczne, co gwarantuje długowieczność i łatwą pielęgnację. To trwałe i estetyczne rozwiązanie do wykończenia nawierzchni wokół domu, ogrodu czy podjazdu.",
    images: [
      {
        src: "kostka-brukowa-1.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-2.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-3.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-4.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-5.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-6.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-7.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-8.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-9.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-10.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-11.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-12.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-13.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-14.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-15.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-16.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-17.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-18.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-19.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
      {
        src: "kostka-brukowa-20.webp",
        manufacturer: "",
        name: "Kostka brukowa",
      },
    ],
  },
  {
    id: 2,
    name: "kruszywa",
    title: "Kruszywa",
    description:
      "Dostarczamy wysokiej jakości kruszywa, które znajdują zastosowanie w budownictwie drogowym, budownictwie mieszkaniowym oraz w ogrodach. Nasze kruszywa są starannie selekcjonowane, co zapewnia ich wysoką jakość i trwałość.",
    images: [
      {
        src: "kruszywa-1.webp",
        manufacturer: "",
        name: "Kruszywa",
      },
      {
        src: "kruszywa-2.webp",
        manufacturer: "",
        name: "Kruszywa",
      },
      {
        src: "kruszywa-3.webp",
        manufacturer: "",
        name: "Kruszywa",
      },
      {
        src: "kruszywa-4.webp",
        manufacturer: "",
        name: "Kruszywa",
      },
      {
        src: "kruszywa-5.webp",
        manufacturer: "",
        name: "Kruszywa",
      },
      {
        src: "kruszywa-6.webp",
        manufacturer: "",
        name: "Kruszywa",
      },
      {
        src: "kruszywa-7.webp",
        manufacturer: "",
        name: "Kruszywa",
      },
      {
        src: "kruszywa-8.webp",
        manufacturer: "",
        name: "Kruszywa",
      },
      {
        src: "kruszywa-9.webp",
        manufacturer: "",
        name: "Kruszywa",
      },
      {
        src: "kruszywa-10.webp",
        manufacturer: "",
        name: "Kruszywa",
      },
    ],
  },
  {
    id: 3,
    name: "piasek_dekoracyjny",
    title: "Piasek dekoracyjny",
    description:
      "Zapewniamy wysokiej jakości piasek dekoracyjny, idealny do ogrodów, alejek i przestrzeni publicznych. Nasz piasek jest starannie selekcjonowany, co zapewnia jego estetykę i trwałość, doskonale uzupełniając każdy projekt aranżacji przestrzeni.",
    images: [],
  },
  {
    id: 4,
    name: "kamienie_dekoracyjne",
    title: "Kamienie dekoracyjne",
    description:
      "W naszej ofercie posiadamy kamienie dekoracyjne, idealne do aranżacji ogrodów, rabat i oczek wodnych. Wybierz spośród różnych kolorów i kształtów, aby nadać swojej przestrzeni unikalny charakter.",
    images: [],
  },
  {
    id: 5,
    name: "ogrodzenia_panelowe",
    title: "Ogrodzenia panelowe",
    description:
      "W naszej ofercie znajdziesz solidne i estetyczne ogrodzenia panelowe, idealne do zabezpieczenia posesji. Trwałe i łatwe w montażu panele dostępne są w różnych wysokościach oraz kolorach, co pozwala na ich dopasowanie do każdego otoczenia.",
    images: [
      {
        src: "panelowe-1.webp",
        manufacturer: "",
        name: "Ogrodzenie panelowe",
      },
    ],
  },
  {
    id: 6,
    name: "ogrodzenia_modułowe",
    title: "Ogrodzenia modułowe",
    description:
      "Oferujemy nowoczesne ogrodzenia modułowe, które doskonale łączą funkcjonalność z estetyką. Dostępne w różnych stylach i kolorach, zapewniają nie tylko solidne zabezpieczenie, ale także elegancki wygląd, który harmonijnie wpisuje się w każde otoczenie.",
    images: [
      {
        src: "modułowe-joniec-1.webp",
        manufacturer: "joniec",
        name: "ROMA Diamond",
      },
      {
        src: "modułowe-joniec-2.webp",
        manufacturer: "joniec",
        name: "ROMA Perfect",
      },
      {
        src: "modułowe-joniec-3.webp",
        manufacturer: "joniec",
        name: "ROMA Mega",
      },
      {
        src: "modułowe-joniec-4.webp",
        manufacturer: "joniec",
        name: "ROMA Nova",
      },
      {
        src: "modułowe-joniec-5.webp",
        manufacturer: "joniec",
        name: "GORC Peak",
      },
      {
        src: "modułowe-1.webp",
        manufacturer: "",
        name: "Ogrodzenie modułowe",
      },
      {
        src: "modułowe-2.webp",
        manufacturer: "",
        name: "Ogrodzenie modułowe",
      },
      {
        src: "modułowe-3.webp",
        manufacturer: "",
        name: "Ogrodzenie modułowe",
      },
    ],
  },

  {
    id: 7,
    name: "beton",
    title: "Beton",
    description:
      "Oferujemy wysokiej jakości beton do budownictwa drogowego, mieszkaniowego i przemysłowego. Nasz starannie formułowany beton zapewnia wysoką wytrzymałość i trwałość, idealny do realizacji nawet najbardziej wymagających projektów.",
    images: [],
  },
  {
    id: 8,
    name: "chemia",
    title: "Chemia",
    description:
      "Dostarczamy wysokiej jakości środki chemiczne do pielęgnacji kostki brukowej, idealne do przestrzeni publicznych i prywatnych. Nasze produkty skutecznie czyszczą, impregnują i chronią kostkę, zapewniając jej trwałość i estetyczny wygląd. Oferujemy również preparaty do usuwania mchów, porostów i chwastów, co ułatwia konserwację i przedłuża żywotność nawierzchni.",
    images: [
      {
        src: "chemia-joniec-1.webp",
        manufacturer: "joniec",
        name: "Chemia do betonu i ogrodzeń",
      },
      {
        src: "chemia-joniec-2.webp",
        manufacturer: "joniec",
        name: "Farby do ogrodzeń betonowych",
      },
      {
        src: "chemia-joniec-3.webp",
        manufacturer: "joniec",
        name: "Chemia do ogrodzeń",
      },
      {
        src: "chemia-feda-1.webp",
        manufacturer: "feda",
        name: "Chemia czyszcząca",
      },
      {
        src: "chemia-feda-2.webp",
        manufacturer: "feda",
        name: "Impregnaty do kostki brukowej",
      },
      {
        src: "chemia-feda-3.webp",
        manufacturer: "feda",
        name: "Impregnaty do ogrodzeń betonowych",
      },
      {
        src: "chemia-cts-1.webp",
        manufacturer: "cts",
        name: "Impregnaty",
      },
      {
        src: "chemia-cts-2.webp",
        manufacturer: "cts",
        name: "Zmywacze",
      },
    ],
  },
];

export default products;
